import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "What Is E-learning?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "E-learning is the learning, education or training delivered online through a computer or other digital devices."
      }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "Why E Learning Is Important For Business?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "E-learning materials are saved online, so your employee can access vital resources anytime they encounter difficult situations or questions. E-learning helps organizations to create high-quality and more effective training experiences for employees.Codestaff can help you."
      }
    }
}

const productMeta = {
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": "elearning Consultant",
    "image": "https://codestaff.io/freelance-elearning.jpg",
    "description": "Freelance e Learning Professional at Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote e learmimg freelancer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
      "@type": "Offer",
      "url": "https://codestaff.io/elearning",
      "priceCurrency": "USD",
      "price": "99",
      "priceValidUntil": "2020-05-27",
      "availability": "https://schema.org/OnlineOnly",
      "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "1",
      "reviewCount": "1"
    },
    "review": {
      "@type": "Review",
      "name": "Prime Patriot",
      "reviewBody": "Everything went great.",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1"
      },
      "datePublished": "2020-05-06",
      "author": {"@type": "Person", "name": "Prime Patriot"},
      "publisher": {"@type": "Organization", "name": "Freelancer Website"}
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const Elearning = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Elearning Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Elearning Developers. Top
                                    companies and start-ups choose Codestaff professional Elearning Developers
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Elearning Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE ELEARNING EXPERTS' banner='/elearning.png' bannerAlt='elearning banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Elearning